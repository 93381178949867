@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Crimson+Text&family=Hahmlet:wght@600&family=Lora:wght@600&family=Titillium+Web:wght@700&display=swap');


#btn-back-to-top {
  position: fixed;
  display: none;
}

.video-docker video {
  top: 50%;
  left: 50%;
  transform: translate(-0%, -20%);
}
.video-docker::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1;
}

.video-content {
  z-index: 2;
}

.new-line {
  white-space: pre-line;
}